import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const transactionServices = {
    get,
    getOne,
    cancel,
    validate,
    getByUser

};

async function get(params) {
    const p = params != null ? params : ''

    try {
        let rets = await axiosInstance.get(`${baseUrl}/transactions${p}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getOne(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/transactions/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function cancel(id) {
    try {
        let rets = await axiosInstance.put(`${baseUrl}/transactions/${id}/cancel`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function validate(id) {
    try {
        let rets = await axiosInstance.put(`${baseUrl}/transactions/${id}/validate`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getByUser(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/transactions/user/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}