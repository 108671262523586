<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { imageQrBaseUrl } from '../../../../constants/config';
import { transactionServices } from "../../../../services/transaction.service";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
/**
 * Blank page component
 */
export default {
    page: {
        title: "Transactions",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, DatePicker, Multiselect },
    data() {
        return {
            title: "Transactions",
            transactions: [],
            items: [],
            imageQrBaseUrl,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "date", sortable: true },
                { key: "transactionId", sortable: true },
                { key: "operateur", sortable: true },
                { key: "number", label: 'Numéro', sortable: true },
                { key: "etat", sortable: true },
                { key: "montant", sortable: true },
                { key: "Options", sortable: true }
            ],
            selectedEtat: {
                id: 2,
                state: 'Tous les paiements'
            },
            etats: [
                {
                    id: 0,
                    state: 'FAILED'
                },
                {
                    id: 1,
                    state: 'SUCCESS'
                },
                {
                    id: 2,
                    state: 'Tous les paiements'
                }
            ],
            daterange: '',
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.transactions.length;
        }
    },
    mounted() {
        this.getTransactions();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getTransactions() {
            this.transactions = await transactionServices.get();
        },
        showDetails(item) {
            this.$router.push(`/events/transactions/${item.id}/detail`);
        },
        async chooseDate(e) {
            const params = `?startDate=${new Date(e[0]).toISOString()}&endDate=${new Date(e[1]).toISOString()}&state=${this.selectedEtat.id}`;
            this.transactions = await transactionServices.get(params);
        },
        async clearable() {
            const params = `?state=${this.selectedEtat.id}`;
            this.transactions = await transactionServices.get(params);
        },
        async selectEvent(e) {
            const dates = this.daterange[0] != null ? `?startDate=${new Date(this.daterange[0]).toISOString()}&endDate=${new Date(this.daterange[1]).toISOString()}&` : '?';
            const states = e != null ? `state=${e.id}` : ''
            const params = `${dates}${states}`;
            this.transactions = await transactionServices.get(params);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-md-3">
                <date-picker
                    v-model="daterange"
                    format="DD/MM/YYYY"
                    range
                    append-to-body
                    confirm
                    @clear="clearable"
                    @confirm="chooseDate"
                    placeholder="Selectionner votre rangé de date"
                ></date-picker>
            </div>
            <div class="col-md-3">
                <multiselect @input="selectEvent" v-model="selectedEtat" track-by="id" :options="etats" label="state" :multiple="false"></multiselect>
            </div>
       </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="transactions" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                <template v-slot:cell(date)="r">
                                        {{ r.item.dateTransaction | defautTableDateTime2 }}
                                </template>
                                <template v-slot:cell(etat)="r">
                                    <span v-if="r.item.etat == 'SUCCES'"
                                    class="badge"
                                    :class="{
                                        'bg-success': true,
                                    }"> {{ r.item.etat }}</span>
                                    <span v-if="r.item.etat != 'SUCCES'"
                                    class="badge"
                                    :class="{
                                        'bg-danger': true,
                                    }"> {{ r.item.etat }}</span>
                                </template>
                                <template v-slot:cell(montant)="r">
                                    <span
                                        class="badge"
                                        :class="{
                                            'bg-primary': true,
                                        }"> {{ r.item.montant | currencyFormat }} </span>
                                </template>
                                <template v-slot:cell(options)="r">
                                    <button @click="showDetails(r.item)" title="Détail de la transaction" class="btn btn-warning btn-sm"><i class="fa fa-eye"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>